import Article from "pages/article/[id]";
import Blog from "pages/blog";
import Contact from "pages/contact";
import Donations from "pages/donations";
import Home from "pages/home";
import Product from "pages/product/[id]";
import QrPaws from "pages/qrPaws";

import SignIn from "pages/signin";
import Store from "pages/store";
import TermsConditions from "pages/terms-&-conditions";
import React from "react";
import { Navigate, Route, Routes as Switch } from "react-router-dom";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="*" element={<Navigate to={`/`} replace />} />
      <Route element={<Home />} path="/" />
      <Route element={<SignIn />} path="/signin" />
      <Route element={<Donations />} path="/donations" />
      <Route element={<TermsConditions />} path="/terms-&-conditions" />
      <Route element={<Blog />} path="/blog" />
      <Route element={<Article />} path="/article:id" />
      <Route element={<Store />} path="/store" />
      <Route element={<Product />} path="/product:id" />
      <Route element={<Contact />} path="/contact" />
      <Route element={<QrPaws />} path="/qrPaws/:id" />
    </Switch>
  );
};

export default Routes;

//
// utils
//

import { OAuthExtension } from "@magic-ext/oauth";
import { SolanaExtension } from "@magic-ext/solana";
import { Magic } from "magic-sdk";

const rpcUrl = `https://devnet.helius-rpc.com/?api-key=${process.env.HELIUS_API_DEVNET}`;

const createMagic = (key: string) => {
  return new Magic(key, {
    extensions: [
      new OAuthExtension(),
      new SolanaExtension({
        rpcUrl,
      }),
    ],
  });
};

export const magic = createMagic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY!);

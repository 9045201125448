import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { articles } from "utils/articles";

export default function Article() {
  const location = useLocation();

  const article = useMemo(() => {
    return articles?.find(({ id }) => id === `${location.state.id}`);
  }, [location]);

  return (
    <section className="text-[#94a3b8] body-font overflow-hidden">
      <main className="mt-24">
        <div
          className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative"
          style={{ height: "35em" }}
        >
          <div
            className="absolute left-0 bottom-0 w-full h-full z-10"
            style={{
              backgroundImage:
                "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7))",
            }}
          ></div>
          <img
            src={article?.image}
            alt=""
            className="absolute left-0 top-0 w-full h-full z-0 object-cover rounded-md"
          />
          <div className="p-4 absolute bottom-0 left-0 z-20 backdrop-blur-sm bg-black/30 w-full rounded-b-lg">
            <h2 className="text-2xl font-semibold text-gray-100 leading-tight">
              {article?.title}
            </h2>
            <div className="flex mt-3">
              <img
                src="https://avatars.githubusercontent.com/u/122798210?s=400&u=3f4a4d1aed246520c6de020e381bfea3f2cc62df&v=4"
                className="h-10 w-10 rounded-full mr-2 object-cover"
                alt=""
              />
              <div>
                <p className="font-semibold text-gray-200 text-sm">
                  {" "}
                  LinkPetsApp
                </p>
                <p className="font-semibold text-gray-400 text-xs">
                  {article?.date}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 lg:px-0 mt-4 text-[#94a3b8] max-w-screen-md mx-auto text-lg leading-relaxed">
          <div className="px-4 bg-purple-500 text-sm text-gray-200 inline-flex items-center justify-center mb-2 rounded-3xl">
            {article?.type}
          </div>
          {article?.content.map((item, i) => {
            return (
              <div key={i}>
                <h2 className="text-lg text-gray-200 font-semibold">
                  {item.title}
                </h2>

                <p className="pb-6">{item.paragraph}</p>
              </div>
            );
          })}
        </div>
      </main>
    </section>
  );
}

export const products = [
  {
    id: "asdasdasd",
    title: "Roupão de secagem rápida",
    image:
      "https://ae01.alicdn.com/kf/S351287c9da4a42699e4f1184d44596ddc/Roup-o-de-secagem-r-pida-de-microfibra-para-c-es-e-gatos-toalhas-de-banho.jpg_.webp",
    price: "R$22,44",
    description:
      "Roupão de secagem rápida de microfibra para cães e gatos, toalhas de banho para cães pequenos médios e grandes.",
    stars: 4.5,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DFXVS6v",
  },
  {
    id: "qweqweqweq",
    title: "Round Head Pet Toothbrush",
    image:
      "https://ae01.alicdn.com/kf/Sc9c97bd456d6478fbd634dd1aa6e4b033/Round-Head-Pet-Toothbrush-Dentes-Cuidados-Escova-macia-Ferramenta-de-limpeza-oral-Remove-o-mau-h.jpg_.webp",
    price: "R$15,48",
    description:
      "Round Head Pet Toothbrush, Dentes Cuidados, Escova macia, Ferramenta de limpeza oral, Remove o mau hálito, tártaro, cão, gato, Pequenos Animais Suprimentos.",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DDHWf53",
  },
  {
    id: "zxcxzcc",
    title: "Chapéu de Natal",
    image:
      "https://ae01.alicdn.com/kf/S535dc72426f14ebca973aa6e6501e83dc/Chap-u-de-Natal-para-Animais-Acess-rios-para-C-es-e-Gatos-Filhotes-e-Gatos.jpg_.webp",
    price: "R$4,99",
    description:
      "Chapéu de Natal para Animais, Acessórios para Cães e Gatos, Filhotes e Gatos, Filhotes e Cães Pequenos, Fantasias, Pet Shop",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DEdjJwd",
  },
  {
    id: "ljkjkljlk",
    title: "Elétrica Handheld Cat Scalp Massager",
    image:
      "https://ae01.alicdn.com/kf/Sbaddce1ce39f4ac1819ef62f67489801E/El-trica-Handheld-Cat-Scalp-Massager-imperme-vel-cabe-a-macia-confort-veis-modos-ajust-veis.jpg_.webp",
    price: "R$37,85",
    description:
      "Elétrica Handheld Cat Scalp Massager, impermeável cabeça macia, confortáveis modos ajustáveis, Pet Shop",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DCr0V6V",
  },
  {
    id: "nbmnbmnbmnb",
    title: "Almofada lambendo silicone",
    image:
      "https://ae01.alicdn.com/kf/S4958fedad1ba4472b81627d9fc117ffb4/Almofada-lambendo-silicone-para-pet-Dog-Lick-Pad-Manteiga-de-amendoim-de-banho-Slow-Eating-Licking.jpg_.webp",
    price: "R$30,70",
    description:
      "Almofada lambendo silicone para pet, Dog Lick Pad, Manteiga de amendoim de banho, Slow Eating Licking Feeder, Cat Mat, Produto de alimentação",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DeOXjN7",
  },
  {
    id: "hfgghjghjhj",
    title: "Christmas Snowman Pet Cosplay Fantasia",
    image:
      "https://ae01.alicdn.com/kf/Sc848b3b3426c4168b57d79daeb7d83df9/Christmas-Snowman-Pet-Cosplay-Fantasia-Fofos-e-Confort-veis-C-o-Roupa-Ano-Novo-Festa-de.jpg_.webp",
    price: "R$15,26",
    description:
      "Christmas Snowman Pet Cosplay Fantasia, Fofos e Confortáveis, Cão Roupa, Ano Novo, Festa de Férias, Vestir, Inverno",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DFIJjWl",
  },

  {
    id: "jhasdjkjhasd",
    title: "Escova removedor de cabelo em aço inoxidável",
    image:
      "https://ae01.alicdn.com/kf/S15025caf74354364976dedc956ac6104D/Escova-removedor-de-cabelo-em-a-o-inoxid-vel-para-animais-escova-de-beleza-antiderrapante-equipamento.jpg_.webp",
    price: "R$15,17",
    description:
      "Escova removedor de cabelo em aço inoxidável para animais, escova de beleza antiderrapante, equipamento de higiene para cães, pente para gatos e cães",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_Dej7nQl",
  },
  {
    id: "gdsgvsdfvsdv",
    title: "Pet Hoodies para Cães Pequenos",
    image:
      "https://ae01.alicdn.com/kf/Sc207c11b58d14a1d985a43194ab6bb589/Pet-Hoodies-para-C-es-Pequenos-Casaco-de-Cachorro-Traje-de-Labrador-Roupa-Pet-Mantenha-Roupas.jpg_.webp",
    price: "R$6,72",
    description:
      "Pet Hoodies para Cães Pequenos, Casaco de Cachorro, Traje de Labrador, Roupa Pet, Mantenha Roupas Quentes, Outono e Inverno",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DEOCojx",
  },
  {
    id: "p[osdvmvsd",
    title: "Simulação de pássaro interativo",
    image:
      "https://ae01.alicdn.com/kf/Sa7bbf7990c0a42f685ec8c4c4a107d43F/Simula-o-de-p-ssaro-interativo-engra-ado-brinquedo-da-vara-do-gato-peludo-pena-p.png_.webp",
    price: "R$9,26",
    description:
      "Simulação de pássaro interativo engraçado brinquedo da vara do gato peludo pena pássaro com sino otário gato brinquedo da vara gatinho jogando acessórios para animais de estimação",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DFv4MGN",
  },
  {
    id: "sospitesvnkdskf",
    title: "Impermeável dupla camada cat litter mat",
    image:
      "https://ae01.alicdn.com/kf/Sd12dd1c597ee439cb738bc3cc1c052b2t/Imperme-vel-dupla-camada-cat-litter-mat-EVA-dobr-vel-pet-carpet-WC-cama-almofadas-para.jpg_.webp",
    price: "R$4,99",
    description:
      "Impermeável dupla camada cat litter mat, EVA dobrável pet carpet, WC cama almofadas para gatos, Pet fornecimentos",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DdSPUwp",
  },
  {
    id: "hgjskgjdsslks",
    title: "10 pçs colorido gato brinquedo bola",
    image:
      "https://ae01.alicdn.com/kf/Hdd7ffd356ccc4590a2954a43a12114daK/10-p-s-colorido-gato-brinquedo-bola-interativa-gato-brinquedos-jogar-mastigar-chocalho-scratch-espuma-natural.jpg_.webp",
    price: "R$11,34",
    description:
      "10 pçs colorido gato brinquedo bola interativa gato brinquedos jogar mastigar chocalho scratch espuma natural bola treinamento suprimentos para animais de estimação",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DkzrYRx",
  },
  {
    id: "poiwerewrwv",
    title: "Esteira de refrigeração",
    image:
      "https://ae01.alicdn.com/kf/S4a6d90fec74a41b79812cd9bae5277d7b/Esteira-de-refrigera-o-para-c-es-pequenos-e-grandes-cama-dur-vel-cobertor-de-ver.jpg_.webp",
    price: "R$9,57",
    description:
      "Esteira de refrigeração para cães pequenos e grandes, cama durável, cobertor de verão, gelo, sofá, acessórios para animais, extra grande",
    stars: 4.8,
    brand: "PET",
    link: "https://s.click.aliexpress.com/e/_DEGZP57",
  },
];

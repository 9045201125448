export default function FeaturesBlocks() {
  //   Smart Pet Tracking: Keywords: "tracking", "location", "pet tracker"
  // Health & Wellness Insights: Keywords: "health", "wellness", "medical", "heart", "vet"
  // Tailored Nutrition Plans: Keywords: "nutrition", "food", "diet", "balance", "bowl"
  // Training Made Fun: Keywords: "training", "education", "paw", "learning", "fun"
  // Pet Community Connections: Keywords: "community", "connection", "pets", "group", "social"
  // Seamless Shopping Experience: Keywords: "shopping", "cart", "marketplace", "store", "bag"

  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="text-3xl font-bold mb-4 text-gray-300">
              Explore soluções personalizadas para pets
            </h2>
            <p className="text-xl text-[#94a3b8]">
              De rastreamento avançado e insights de bem-estar a treinamento
              interativo e conexões vibrantes na comunidade. Descubra um mundo
              onde tecnologia e companheirismo se unem para aprimorar a vida do
              seu pet.
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            {featuresArray?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="relative flex flex-col items-center p-6 bg-gray-900 shadow-lg shadow-[#7233ff]/50 rounded-xl"
                >
                  <div className="flex justify-center items-center w-8 h-8 my-4 bg-[#7233ff] rounded-full shadow flex-shrink-0 ml-3">
                    {item.icon}
                  </div>

                  <h4 className="font-bold leading-snug tracking-tight mb-1 text-gray-300 truncate">
                    {item.title}
                  </h4>
                  <p className="text-[#94a3b8] text-center">
                    {item.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

const featuresArray = [
  {
    title: "Dashboard Social para Amantes de Pets",
    description:
      "Plataforma interativa para compartilhar imagens, momentos, encontrar cuidadores, localizar veterinários e descobrir dicas de cuidados.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-people-fill"
        viewBox="0 0 16 16"
      >
        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
      </svg>
    ),
  },
  {
    title: "Localização de Pets Perdidos",
    description:
      "Ferramenta integrada para facilitar a busca e localização de pets perdidos, promovendo a rápida reunificação com seus tutores.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-qr-code-scan"
        viewBox="0 0 16 16"
      >
        <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z" />
        <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z" />
        <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z" />
        <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z" />
        <path d="M12 9h2V8h-2z" />
      </svg>
    ),
  },
  {
    title: "Consultas Online com Veterinários",
    description:
      "Acesso simplificado a consultas veterinárias online, proporcionando orientações e cuidados de profissionais credenciados.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-heart-pulse-fill"
        viewBox="0 0 16 16"
      >
        <path d="M1.475 9C2.702 10.84 4.779 12.871 8 15c3.221-2.129 5.298-4.16 6.525-6H12a.5.5 0 0 1-.464-.314l-1.457-3.642-1.598 5.593a.5.5 0 0 1-.945.049L5.889 6.568l-1.473 2.21A.5.5 0 0 1 4 9z" />
        <path d="M.88 8C-2.427 1.68 4.41-2 7.823 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C11.59-2 18.426 1.68 15.12 8h-2.783l-1.874-4.686a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.88Z" />
      </svg>
    ),
  },
  {
    title: "Conexão entre Cuidadores e Donos de Pets",
    description:
      "Plataforma que conecta cuidadores de pets a donos que procuram serviços de hospedagem, promovendo confiança e segurança.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-house-heart-fill"
        viewBox="0 0 16 16"
      >
        <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707z" />
        <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018" />
      </svg>
    ),
  },
  {
    title: "Comunidade Pet por Raças",
    description:
      "Segmentação da comunidade por raças, permitindo interações específicas e compartilhamento de informações relevantes para cada tipo de pet.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-chat-left-text-fill"
        viewBox="0 0 16 16"
      >
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
      </svg>
    ),
  },
  {
    title: "Alerta de Pets Perdidos e Abandonados",
    description:
      "Sistema de alerta para pets perdidos e abandonados, contribuindo para a mobilização de ONGs e comunidades na busca por soluções.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#fff"
        className="bi bi-exclamation-triangle-fill"
        viewBox="0 0 16 16"
      >
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
      </svg>
    ),
  },
];

import { useUser } from "context/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./logo";
import MobileMenu from "./mobile-menu";

export default function Header() {
  const [top, setTop] = useState<boolean>(true);
  const { user } = useUser();

  const navigate = useNavigate();

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? "bg-gray-900 backdrop-blur-sm shadow-lg" : ""
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            <Logo />
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              {socialNetworks?.map((item, i) => {
                return (
                  <li key={i}>
                    {item.title !== "Entrar" && (
                      <div
                        onClick={() =>
                          navigate(item.route, {
                            replace: item.title === "Inicio" ? true : false,
                          })
                        }
                        className="font-medium cursor-pointer text-[#94a3b8] hover:text-white px-4 py-3 flex items-center transition duration-150 ease-in-out"
                      >
                        {item.title}
                      </div>
                    )}

                    {item.title === "Entrar" && (
                      <div
                        onClick={() => navigate(item.route)}
                        className="cursor-pointer flex items-center text-[#94a3b8] hover:text-white justify-center bg-[#7233ff] px-4 rounded-3xl"
                      >
                        <div className="font-medium   flex items-center transition duration-150 ease-in-out">
                          {!user ? item.title : user.userName}
                        </div>
                        {user ? (
                          <svg
                            className="w-4 h-4 fill-current shrink-0 ml-2"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                          >
                            <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" />
                          </svg>
                        ) : (
                          <svg
                            className="w-3 h-3 fill-current shrink-0 ml-2"
                            viewBox="0 0 12 12"
                          >
                            <path
                              d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                              fillRule="nonzero"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>
    </header>
  );
}

const socialNetworks = [
  {
    title: "Inicio",
    route: "/",
  },
  // {
  //   title: "Loja",
  //   route: "/store",
  // },
  {
    title: "Blog",
    route: "/blog",
  },
  {
    title: "Doações",
    route: "/donations",
  },
  {
    title: "Termos",
    route: "/terms-&-conditions",
  },
  {
    title: "Contato",
    route: "/contact",
  },
  {
    title: "Entrar",
    route: "/signin",
  },
];

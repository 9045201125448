import React, { createContext, useContext, useMemo } from "react";

type PetContextProps = {
  pet: any;
};

interface Props {
  children: JSX.Element;
}

export const PetContext = createContext<PetContextProps | undefined>(undefined);

const PetProvider: React.FC<Props> = ({ children, ...rest }) => {
  const [pet, setPet] = React.useState(null);

  const value = useMemo(() => {
    return {
      pet,
    };
  }, [pet]);

  return (
    <PetContext.Provider value={value} {...rest}>
      {children}
    </PetContext.Provider>
  );
};

export const usePet = (): PetContextProps => {
  const context = useContext(PetContext);

  if (context === undefined) {
    throw new Error("usePet must be used within an PetProvider");
  }

  return context;
};

export default PetProvider;

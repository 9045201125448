import axios from "axios";
import { TMessage, TPet, TQrPaws } from "context/pet/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { initializeSocket, socket } from "../../socket";
import {
  IconAparence,
  IconFavorite,
  IconMore,
  IconSend,
  IconSettings,
} from "./Icons";

export default function QrPaws() {
  const { id } = useParams<{ id: string }>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const messagesEndRef = useRef<any>(null);
  const [qrPaws, setQrPaws] = useState<TQrPaws>();
  const [messages, setMessages] = useState<TMessage[]>();
  const [message, setMessage] = useState("");
  const [pet, setPet] = useState<TPet>();

  useEffect(() => {
    initializeSocket();

    return () => {
      socket.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (!qrPaws) return;
    socket.on(`newChatMessage-${pet?.user.id}}`, (data) => {
      setMessages((prev) => [...prev!, data]);
    });
  }, [pet?.user.id, qrPaws]);

  useEffect(() => {
    if (!id) return;

    let qrPawsIdStorage = localStorage.getItem(`linkpetsapp@qr_paws_id_${id}`);
    let petIdStorage = localStorage.getItem(`linkpetsapp@pet_id_${id}`);

    if (petIdStorage !== id) {
      creatQrPaws(id);
    } else if (qrPawsIdStorage && qrPawsIdStorage !== null) {
      getQrPaws(qrPawsIdStorage);
    }

    getPetById(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPetById = useCallback(async (id: string) => {
    try {
      const petRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/pet/${id}`
      );

      setPet(petRes.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getQrPawsMessages = useCallback(async (id: string) => {
    try {
      const qrPawsMessagesRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/qrAlerts/${id}/messages`
      );

      setMessages(qrPawsMessagesRes.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const sendPushNotification = useCallback(
    async (
      tokenNotification: string,
      content: string,
      receiveBy: string,
      sendBy: string,
      qrPawsId: string
    ) => {
      try {
        const messageData = {
          to: tokenNotification,
          sound: "default",
          title: "QrPaws alert!",
          body: content || "",
          image: "",
          mutable_content: true,
          data: {
            qrPawsId,
            content,
            sendBy,
            receiveBy,
          },
        };

        await fetch("https://exp.host/--/api/v2/push/send", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messageData),
        });
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const content = (
        e.currentTarget.elements.namedItem("content") as HTMLInputElement
      ).value;

      if (!content || !qrPaws) return;

      try {
        const messageRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/sendMessage`,
          {
            content: content,
            receiveBy: qrPaws?.pet.userId,
            sendBy: qrPaws?.id,
            qrPawsId: qrPaws?.id,
          }
        );

        setMessages((prev) => [...prev!, messageRes.data]);
        setMessage("");

        // sendPushNotification(
        //   qrPaws?.pet.user.tokenNotification,
        //   content,
        //   qrPaws?.pet.userId,
        //   qrPaws?.id,
        //   qrPaws?.id
        // );
      } catch {}
    },
    [qrPaws]
  );

  const getQrPaws = useCallback(async (id: string) => {
    try {
      const qrPawsRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/getQrAlert/${id}`
      );

      setQrPaws(qrPawsRes.data);
      getQrPawsMessages(id);
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creatQrPaws = useCallback(
    async (petId: string) => {
      try {
        const location = await axios.get("https://api.techniknews.net/ipgeo/");
        const qrPawsRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/createQrAlert`,
          {
            petId,
            lat: location.data.lat.toString() || "",
            long: location.data.lon.toString() || "",
            address: `${location.data.city || ""}, ${
              location.data.regionName || ""
            } - ${location.data.country || ""}`,
          }
        );
        localStorage.setItem(`linkpetsapp@pet_id_${petId}`, petId);
        qrPawsRes.data.id &&
          localStorage.setItem(
            `linkpetsapp@qr_paws_id_${petId}`,
            qrPawsRes.data.id
          );

        if (!qrPaws) return;

        const messageRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/sendMessage`,
          {
            content: "Olá, encontrei o seu pet!",
            receiveBy: qrPaws?.pet.userId,
            sendBy: qrPaws?.id,
            qrPawsId: qrPaws?.id,
          }
        );

        setMessages((prev) => [...prev!, messageRes.data]);

        sendPushNotification(
          qrPaws?.pet.user.tokenNotification,
          "Olá, encontrei o seu pet!",
          qrPaws?.pet.userId,
          qrPaws?.id,
          qrPaws?.id
        );
      } catch {}
    },
    [qrPaws, sendPushNotification]
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  function Message({ content, isRight }: any) {
    return (
      <div className={`flex ${isRight ? "justify-end" : ""}`}>
        <div
          className={`${
            isRight ? "bg-purple-200" : "bg-gray-300"
          } text-black p-2 rounded-lg max-w-xs`}
        >
          {content}
        </div>
      </div>
    );
  }

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const MessageList = useCallback(
    (messages: TMessage[]) => {
      if (!messages || !qrPaws) return;
      return (
        <div className="flex-1 overflow-y-auto p-4">
          <div className="flex flex-col space-y-2">
            {messages.map((message, index) => (
              <Message
                key={index}
                content={message.content}
                isRight={message.sendBy === qrPaws?.id}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
      );
    },
    [qrPaws]
  );

  if (!id) {
    return (
      <div>
        <p>Error ao procurar petId</p>
      </div>
    );
  }

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-start pt-12">
            <p className="font-bold tracking-tight text-gray-200">
              Seja bem-vindo ao LinkPetsApp!
            </p>

            <p className="text-sm text-[#94a3b8]">
              Olá anjo protetor, se você está aqui, provavelmente encontrou um
              dos nossos amiguinhos peludos. Compartilhe informações para
              facilitar o reencontro.
            </p>

            <div className="flex items-center my-4">
              <img
                src={pet?.image}
                alt="author"
                className="w-10 h-10 rounded-full overflow-hidden mx-2"
              />
              <div>
                <p className="tracking-tight text-gray-200">{pet?.name}!</p>
                <p className="tracking-tight text-gray-400 text-xs">
                  {pet?.breed}!
                </p>
              </div>
            </div>

            <div className="h-[500px] flex flex-col mx-auto">
              <div className="bg-purple-500/20 p-4 text-white flex justify-between items-center">
                <button
                  id="login"
                  className="hover:bg-purple-400 rounded-md p-1"
                >
                  <img
                    src={pet?.user.image}
                    alt="author"
                    className="w-10 h-10 rounded-full overflow-hidden"
                  />
                </button>
                <span>{pet?.user.name}</span>
                <div className="relative inline-block text-left">
                  <button
                    id="setting"
                    className="hover:bg-purple-400 rounded-md p-1"
                    onClick={toggleDropdown}
                  >
                    <IconSettings />
                  </button>
                  <div
                    id="dropdown-content"
                    className={`${
                      isDropdownOpen ? "" : "hidden"
                    } absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg p-2`}
                  >
                    <NavLink
                      id="RouterNavLink"
                      to="/contact"
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md"
                    >
                      <IconAparence />
                      Appearance
                    </NavLink>

                    <NavLink
                      id="RouterNavLink"
                      to="/contact"
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md"
                    >
                      <IconFavorite />
                      Favorite
                    </NavLink>
                    <NavLink
                      id="RouterNavLink"
                      to="/contact"
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md"
                    >
                      <IconMore />
                      More
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="flex-1 overflow-y-auto p-4 ">
                <div className="flex flex-col space-y-2 ">
                  {messages && MessageList(messages)}
                </div>
              </div>

              <form onSubmit={onSubmit} className=" p-4 flex items-center">
                <input
                  type="text"
                  id="content"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your message..."
                  className="flex-1 border rounded-full px-4 py-2 focus:outline-none"
                />
                <button
                  type="submit"
                  disabled={!message}
                  className="bg-purple-500 text-white rounded-full p-2 ml-2 hover:bg-purple-600 focus:outline-none"
                >
                  <IconSend />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const articles = [
  {
    id: "asjdhas",
    title: "Ajude a salvar pets no Rio Grande do Sul",
    image:
      "https://th.bing.com/th/id/OIG2.kOz4AzvQa60qcCWwyVIF?pid=ImgGn",
    content: [
      {
        title: "",
        paragraph:
          "As recentes enchentes no Rio Grande do Sul deixaram um rastro de destruição, afetando não apenas as pessoas, mas também os animais que compartilham nosso ambiente. Muitos pets foram deixados para trás ou perderam seus lares, vagando agora em busca de abrigo e comida. A situação é urgente e cada minuto conta para essas pequenas vidas.",
      },
      {
        title: "Doações",
        paragraph:
          "São uma parte vital da nossa resposta de emergência. Precisamos de ração para alimentar cães e gatos famintos, potes para água e comida, brinquedos para confortá-los em um momento de estresse, e roupinhas para aqueles que estão vulneráveis ao frio. Cada item doado ajuda a aliviar o sofrimento de um pet necessitado.",
      },
      {
        title: "Voluntariado",
        paragraph:
          "É outra forma poderosa de contribuir. Estamos convocando todos aqueles que podem oferecer tempo e habilidades para se juntarem aos nossos esforços. Seja ajudando no resgate dos animais, cuidando deles em abrigos temporários, ou mesmo fornecendo transporte, sua ajuda é inestimável.",
      },
      {
        title: "Divulgação",
        paragraph:
          "Deste apelo por ajuda é crucial. Ao compartilhar este post, você amplia nosso alcance e aumenta as chances de mais ajuda chegar. Use suas redes sociais para espalhar a palavra e criar uma onda de apoio.",
      },
      {
        title: "Cada pequeno gesto conta!",
        paragraph:
          "Lembre-se, Juntos, temos o poder de transformar a adversidade em esperança e garantir que nenhum pet seja esquecido. 🐶🐱",
      },
      {
        title: "",
        paragraph:
          "Participe dessa causa. Seu apoio pode salvar vidas!",
      },
    ],
    date: "9 Maio 2024",
    type: "Ajuda",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
  {
    id: "askljdals",
    title: "Os Benefícios do Exercício Regular",
    image:
      "https://th.bing.com/th/id/OIG2.OCGZMO2mW_ZHJm0gpvO0?pid=ImgGn",
    content: [
      {
        title: "Rotinas de Exercício",
        paragraph:
          "Saiba como estabelecer rotinas de exercício eficazes para manter seu cão saudável e feliz. Explore atividades adequadas para diferentes raças e idades.",
      },
      {
        title: "Vínculo Social",
        paragraph:
          "Além dos benefícios físicos, o exercício regular também fortalece o vínculo emocional entre você e seu cão. Descubra maneiras de tornar os passeios mais divertidos e interativos.",
      },
    ],
    date: "18 Fevereiro 2023",
    type: "Cuidados",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
  {
    id: "poupou",
    title: "Entretenimento de Gatos em Casa",
    image:
      "https://th.bing.com/th/id/OIG2.sApQjLmP1Ai0Yi3Ujw8G?pid=ImgGn",
    content: [
      {
        title: "Brinquedos Catnip",
        paragraph:
          "Descubra os encantos dos brinquedos com catnip para gatos e como eles podem proporcionar horas de entretenimento para seu felino.",
      },
      {
        title: "Atividades Interativas",
        paragraph:
          "Explore atividades interativas que mantêm os gatos ativos e estimulados, promovendo uma vida saudável e feliz em ambientes internos.",
      },
    ],
    date: "25 Março 2023",
    type: "Cuidados",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
  {
    id: "ioppoio",
    title: "Alimentação Adequada para Cães Idosos",
    image:
      "https://th.bing.com/th/id/OIG2.KIptgFt1iG2ovwULttYh?pid=ImgGn",
    content: [
      {
        title: "Necessidades Nutricionais",
        paragraph:
          "Entenda as necessidades nutricionais específicas para cães idosos e como uma dieta adequada pode contribuir para sua saúde e vitalidade.",
      },
      {
        title: "Suplementos Recomendados",
        paragraph:
          "Saiba mais sobre os suplementos alimentares que podem beneficiar cães mais velhos, mantendo-os ativos e com uma qualidade de vida elevada.",
      },
    ],
    date: "10 Abril 2023",
    type: "Cuidados",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
  {
    id: "ghjjhghj",
    title: "Animal de Estimação Exótico",
    image:
      "https://th.bing.com/th/id/OIG2.kOYh66qEbqTI_8pBcxSr?pid=ImgGn",
    content: [
      {
        title: "Benefícios Inesperados",
        paragraph:
          "Descubra benefícios inesperados de ter um animal de estimação exótico, incluindo experiências únicas e a oportunidade de aprender sobre diferentes espécies.",
      },
      {
        title: "Desafios e Responsabilidades",
        paragraph:
          "Conheça os desafios e responsabilidades associados à criação de animais de estimação exóticos, e saiba como se preparar para oferecer um ambiente adequado.",
      },
    ],
    date: "15 Maio 2023",
    type: "Cuidados",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
  {
    id: "lkljgh",
    title: "Raça de Gato para Seu Estilo de Vida",
    image:
      "https://th.bing.com/th/id/OIG2.QkD4_L4QBxnCvuimbjao?pid=ImgGn",
    content: [
      {
        title: "Avaliando Necessidades",
        paragraph:
          "Aprenda a avaliar suas próprias necessidades e estilo de vida ao escolher a melhor raça de gato. Cada raça tem características únicas a considerar.",
      },
      {
        title: "Dicas para Adoção",
        paragraph:
          "Descubra dicas importantes para a adoção responsável de um gato, garantindo que você e seu novo amigo felino tenham uma convivência harmoniosa.",
      },
    ],
    date: "22 Junho 2023",
    type: "Dicas",
    creator: "LinkPetsApp",
    creatorImage: "linkpetsapp_logo.jpg",
  },
];

import { useNavigate } from "react-router-dom";
import { articles } from "utils/articles";

export default function Blog() {
  const navigate = useNavigate();

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 flex flex-wrap mt-24 justify-between">
        {articles?.map((item, i) => {
          return (
            <div className="pb-4 md:w-[32%]" key={i}>
              <div className="h-full border-2 border-purple-800 border-opacity-60 rounded-lg overflow-hidden">
                <div className="w-full flex justify-between items-center">
                  <div className="w-full flex p-2">
                    <div className="p-2 ">
                      <img
                        src="https://avatars.githubusercontent.com/u/122798210?s=400&u=3f4a4d1aed246520c6de020e381bfea3f2cc62df&v=4"
                        alt="author"
                        className="w-10 h-10 rounded-full overflow-hidden"
                      />
                    </div>
                    <div className="pl-2 pt-2 ">
                      <p className="font-bold text-slate-300">LinkPetsApp</p>
                      <p className="text-xs text-slate-400">{item.date}</p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigate("/article:id", {
                        state: { id: item?.id },
                      })
                    }
                    className="text-slate-400 pr-4 cursor-pointer"
                  >
                    <IconArrow />
                  </div>
                </div>

                <img
                  className="lg:h-64 md:h-36 w-full object-cover object-center opacity-50"
                  src={item.image}
                  alt="blog cover"
                />

                <div className="p-2 -mt-16 backdrop-blur-sm bg-black/50 flex items-center justify-between">
                  <p className="title-font font-bold text-gray-300 truncate">
                    {item.title}
                  </p>
                  {/* <div className="flex items-center">
                    <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                      <IconLike />
                      24
                    </span>
                    <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                      <IconComment />
                      89
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export const IconArrow = (props: any) => (
  <svg
    className="w-6 h-6"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M5 12h14"></path>
    <path d="M12 5l7 7-7 7"></path>
  </svg>
);

export const IconLike = (props: any) => (
  <svg
    className="w-4 h-4 mr-1"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
  </svg>
);

export const IconComment = (props: any) => (
  <svg
    className="w-4 h-4 mr-1"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
  </svg>
);

import React, { Fragment } from "react";
import { Toaster } from "react-hot-toast";
import PetProvider from "./pet";
import UserProvider from "./user";

interface Props {
  children: JSX.Element;
}

const AppProvider: React.FC<Props> = ({ children }) => (
  <UserProvider>
    <PetProvider>
      <Fragment>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            style: {
              padding: "18px",
              fontSize: "16px",
            },
          }}
        />{" "}
        {children}
      </Fragment>
    </PetProvider>
  </UserProvider>
);

export default AppProvider;

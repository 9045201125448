import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_URL!, {
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 3, 
  reconnectionDelay: 1000,
});

function onConnect() {
  console.log("Conectado ao servidor de WebSocket");
}

function onDisconnect() {
  console.log("Desconectado do servidor de WebSocket");
}

const initializeSocket = () => {
  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnect);

  socket.on("connect_error", (error) => {
    console.error("Erro de conexão:", error);
  });

  socket.on("error", (error) => {
    console.error("Erro do socket:", error);
  });

  socket.on("reconnect_attempt", () => {
    console.log("Tentando reconectar...");
  });
};

export { socket, initializeSocket };


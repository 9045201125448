import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { products } from "utils/products";

export default function Product() {
  const location = useLocation();

  const product = useMemo(() => {
    return products?.find(({ id }) => id === `${location.state.id}`);
  }, [location.state.id]);

  if (!product) return <></>;

  return (
    <section className="text-gray-700 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <img
            alt="ecommerce"
            className="lg:w-1/2 w-full object-cover object-center rounded-lg border border-gray-200"
            src={product?.image}
            width={560}
            height={640}
            object-fit="cover"
          />
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-sm title-font text-[#94a3b8] tracking-widest">
              {product.brand}
            </h2>
            <h1 className="text-gray-200 text-3xl title-font font-medium mb-1">
              {product?.title}
            </h1>
            <div className="flex mb-4">
              <div className="flex items-center">
                {[1, 2, 3, 4, 5]?.map((item, i) => {
                  if (item > product.stars) {
                    return (
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-4 h-4 text-purple-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                      </svg>
                    );
                  }
                  return (
                    <svg
                      key={i}
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 text-purple-500"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                    </svg>
                  );
                })}
                <span className="text-[#94a3b8] ml-3">
                  {product.stars} Reviews
                </span>
              </div>
            </div>
            <p className="leading-relaxed text-[#94a3b8] mb-4">
              {product.description}
            </p>

            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-200">
                {product.price}
              </span>
              <a
                href={product?.link}
                target="_blank"
                className="flex ml-auto text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-400 rounded"
                rel="noreferrer"
              >
                Comprar
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import { BN, convertToNumber, initialize } from "@drift-labs/sdk";
import { OAuthRedirectResult } from "@magic-ext/oauth";
import {
  //@ts-ignore
  getAccount,
  //@ts-ignore
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import axios, { AxiosResponse } from "axios";
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { MagicUserMetadata, RPCError, RPCErrorCode } from "magic-sdk";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { magic } from "utils/magic";
import { IDarkLocation, TUser } from "./types";
const drifSdkConfig = initialize({ env: "mainnet-beta" });

type UserContextProps = {
  emailLogin: (email: string) => Promise<void>;
  socialLogin: (
    typeProvider: "discord" | "apple" | "twitter" | "google"
  ) => Promise<void>;
  signWaitlist: (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    message: string,
    company: string
  ) => Promise<true | undefined>;
  resetStorage: () => void;
  user: TUser | null | undefined;
  userMagic: OAuthRedirectResult | null;
  publicAddress: string;
  userMetadata: MagicUserMetadata | undefined;
  loadingGlobal: boolean;
  balanceWallet: number;
};

interface Props {
  children: JSX.Element;
}

export const UserContext = createContext<UserContextProps | undefined>(
  undefined
);

const UserProvider: React.FC<Props> = ({ children, ...rest }) => {
  const [loadingGlobal, setLoadingGlobal] = useState<boolean>(false);
  const [user, setUser] = useState<TUser | null>();
  const [userMagic, setUserMagic] = useState<OAuthRedirectResult | null>(null);
  const [publicAddress, setPublicAddress] = useState("");
  const [userMetadata, setUserMetadata] = useState<MagicUserMetadata>();
  const [balanceWallet, setBalanceWallet] = useState(0);

  const connection = useMemo(
    () =>
      new Connection(
        `https://devnet.helius-rpc.com/?api-key=${process.env.HELIUS_API_DEVNET}`
      ),
    []
  );

  const app = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDR_ID,
    appId: process.env.REACT_APP_APP_ID,
  });

  const db = getFirestore(app);

  const userWallet = useMemo(() => {
    if (!userMagic?.magic.userMetadata.publicAddress || !magic) return null;

    return {
      publicKey: userMagic?.magic.userMetadata.publicAddress,
      signAllTransactions: magic.solana.signTransaction,
      signTransaction: magic.solana.signTransaction,
      signMessage: magic.solana.signMessage,
    };
  }, [userMagic]);

  const resetStorage = useCallback(() => {
    localStorage.clear();
    magic.user.logout();
    setUser(null);
  }, []);

  useEffect(() => {
    if (!userWallet?.publicKey) return;

    init();
  }, [userWallet?.publicKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingGlobal(true);

    magic.user.isLoggedIn().then(async (magicIsLoggedIn) => {
      if (magicIsLoggedIn) {
        const metadata = await magic.user.getMetadata();
        if (!metadata.publicAddress) return;
        init();
        setPublicAddress(metadata.publicAddress);
        setUserMetadata(metadata);
      }
    });

    magic.oauth.getRedirectResult().then(
      (response) => {
        setLoadingGlobal(false);
        console.log(response);
        setUserMagic(response);
        localStorage.setItem("user", JSON.stringify(response));
        init();
        return;
      },
      (err) => {
        setLoadingGlobal(false);
        console.log(err);
        return;
      }
    );

    const userLocal = localStorage.getItem("user");
    setUserMagic(JSON.parse(userLocal || "null"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createUser = useCallback(
    async (
      email: string,
      publicAddress: string,
      avatar?: string,
      nickname?: string
    ) => {
      if (!userWallet?.publicKey) return;

      setLoadingGlobal(true);

      try {
        const location = await axios.get("https://api.techniknews.net/ipgeo/");
        let loc = location.data;
        let atIndex = email && email.indexOf("@");
        let name = email && atIndex && email.slice(0, atIndex);

        let newUser: AxiosResponse<TUser> = await axios.post(
          `${process.env.REACT_APP_API_URL}/user`,
          {
            name: nickname || name,
            userName: name || "User",
            image: avatar || "",
            bio: "I'm LinkPetsApp user",
            email,
            publicAddress,
            tokenNotification: "",
            address: {
              continent: `${loc.continent || ""}`,
              country: `${loc.country || ""}`,
              countryCode: `${loc.countryCode || ""}`,
              regionName: `${loc.regionName || ""}`,
              city: `${loc.city || ""}`,
              zip: `${loc.zip || ""}`,
              lat: `${loc.lat.toString() || ""}`,
              lon: `${loc.lon.toString() || ""} `,
              timezone: `${loc.timezone || ""} `,
              currency: `${loc.currency || ""} `,
            },
          }
        );

        setUser(newUser.data);

        return newUser.data;
      } catch {
      } finally {
        setLoadingGlobal(false);
      }
    },
    [userWallet?.publicKey]
  );

  const init = useCallback(async () => {
    if (!userWallet?.publicKey) return;

    try {
      let userFind = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userWallet?.publicKey}`
      );

      let avatar;
      let nickname = userWallet?.publicKey;

      let isMagiUserLoggedIn;

      if (isMagiUserLoggedIn && userMagic?.oauth.userInfo.sources) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        avatar =
          userMagic?.oauth?.provider === "google"
            ? userMagic.oauth.userInfo.picture!
            : `https://cdn.discordapp.com/avatars/${userMagic.oauth.userInfo.sources["https://discord.com/api/users/@me"].id}/${userMagic?.oauth.userInfo.sources["https://discord.com/api/users/@me"].avatar}.png`;
      }

      if (isMagiUserLoggedIn && userMagic?.oauth?.provider === "discord") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        nickname = userMagic?.oauth.userInfo.preferredUsername || nickname;
      }

      if (
        !userFind.data &&
        userMagic?.magic.userMetadata.email &&
        userMagic?.magic.userMetadata.publicAddress
      ) {
        const createUserResponse = await createUser(
          userMagic?.magic.userMetadata.email,
          userMagic?.magic.userMetadata.publicAddress,
          avatar,
          nickname
        );
        setUser(createUserResponse);
      }

      userWallet?.publicKey &&
        localStorage.setItem("wallet", userWallet?.publicKey);

      setLoadingGlobal(false);
      setUser(userFind.data);
    } catch {
      setLoadingGlobal(false);
      resetStorage();

      alert("Error to connect wallet!");
    }
  }, [
    userWallet?.publicKey,
    userMagic?.oauth.userInfo.sources,
    userMagic?.oauth.userInfo.picture,
    userMagic?.oauth.userInfo.preferredUsername,
    userMagic?.oauth?.provider,
    userMagic?.magic.userMetadata.email,
    userMagic?.magic.userMetadata.publicAddress,
    createUser,
    resetStorage,
  ]);

  const socialLogin = useCallback(
    async (typeProvider: "discord" | "apple" | "twitter" | "google") => {
      if (!magic) return alert("Erro magic undefined");
      const redirectURI = window.location.href;

      const scope = typeProvider === "discord" ? ["identify"] : undefined;
      setLoadingGlobal(true);
      try {
        await magic.oauth.loginWithRedirect({
          provider: typeProvider,
          redirectURI,
          scope,
        });
        init();
      } catch {
        setLoadingGlobal(false);
      }
    },
    [init]
  );

  const emailLogin = useCallback(
    async (email: string) => {
      console.log(email);
      if (!magic) return alert("Erro magic undefined");
      setLoadingGlobal(true);
      try {
        await magic.auth.loginWithEmailOTP({
          email,
          showUI: true,
        });

        init();
      } catch (err) {
        console.log(err);
        if (err instanceof RPCError) {
          switch (err.code) {
            case RPCErrorCode.MagicLinkFailedVerification:
            case RPCErrorCode.MagicLinkExpired:
            case RPCErrorCode.MagicLinkRateLimited:
            case RPCErrorCode.UserAlreadyLoggedIn:
              // Handle errors accordingly :)
              break;
          }
        }
      } finally {
        setLoadingGlobal(false);
      }
    },
    [init]
  );

  const signWaitlist = useCallback(
    async (
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      message: string,
      company: string
    ) => {
      const loc = await axios.get("https://api.techniknews.net/ipgeo/");
      const waitlistRef = collection(db, "contact");

      try {
        await addDoc(waitlistRef, {
          email,
          firstName,
          lastName,
          phoneNumber,
          message,
          company,
          timestamp: serverTimestamp(),
          location: (loc.data as IDarkLocation) || "",
        });

        return true;
      } catch (e) {
        console.log(e);
      }
    },
    [db]
  );

  useEffect(() => {
    const checkUserWallet = () => {
      if (!userWallet?.publicKey) {
        setTimeout(checkUserWallet, 100);
      } else {
        getBalanceUsdc();
      }
    };

    checkUserWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWallet?.publicKey]);

  const getBalanceUsdc = useCallback(async () => {
    if (!userWallet || !userWallet.publicKey) return;

    try {
      const token = await getAssociatedTokenAddress(
        new PublicKey(drifSdkConfig.USDC_MINT_ADDRESS),
        new PublicKey(userWallet.publicKey)
      );
      const account = await getAccount(connection, token);

      setBalanceWallet(convertToNumber(new BN(Number(account.amount))));
    } catch (e) {
      setBalanceWallet(0);
      console.log(e);
    }
  }, [connection, userWallet]);

  const value = useMemo(() => {
    return {
      user,
      userMagic,
      publicAddress,
      userMetadata,
      loadingGlobal,
      balanceWallet,
      resetStorage,
      emailLogin,
      socialLogin,
      signWaitlist,
    };
  }, [
    user,
    userMagic,
    publicAddress,
    userMetadata,
    loadingGlobal,
    balanceWallet,
    resetStorage,
    emailLogin,
    socialLogin,
    signWaitlist,
  ]);

  return (
    <UserContext.Provider value={value} {...rest}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }

  return context;
};

export default UserProvider;

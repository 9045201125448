import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import FeaturesElement from "../assets/images/iphone01.webp";
import FeaturesElement1 from "../assets/images/iphone02.webp";
import FeaturesElement2 from "../assets/images/iphone03.webp";

export default function Features() {
  const [tab, setTab] = useState<number>(1);
  const tabs = useRef<HTMLDivElement>(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement)
      tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <section className="relative mt-8">
      {/* Section background (needs .relative class on parent and next sibling elements) */}

      {/* <div className="absolute left-0 right-0 m-auto w-px p-px h-20  transform -translate-y-1/2"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4 text-[#94a3b8]">Explore the solutions</h1>
            <p className="text-xl text-[#94a3b8]">
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur excepteur sint occaecat
              cupidatat.
            </p>
          </div> */}

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">
            {/* Content */}
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
              data-aos="fade-right"
            >
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="text-4xl mb-3 text-gray-300 self-center font-bold">
                  LinkPetsApp
                </h3>

                <p className="text-lg text-[#94a3b8]">
                  Conectando pets a anjos protetores. Descubra o futuro dos
                  cuidados com animais de estimação com o LinkPetsApp, uma
                  aplicação que combina tecnologia avançada, comunicação eficaz
                  e comércio integrado para atender todas as necessidades dos
                  amantes de pets.
                </p>
              </div>

              <div className="justify-center items-center my-4 flex space-x-4">
                <a
                  href="/contact"
                  className="w-full bg-gray-900 hover:shadow-lg hover:shadow-[#7233ff]/50 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5"
                >
                  <svg
                    className="mr-3 w-7 h-7"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="apple"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                    ></path>
                  </svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">Get in the</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">
                      App Store
                    </div>
                  </div>
                </a>
                <a
                  href="/contact"
                  className="w-full bg-gray-900 hover:shadow-lg hover:shadow-[#7233ff]/50 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5"
                >
                  <svg
                    className="mr-3 w-7 h-7"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google-play"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                    ></path>
                  </svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">Get in on</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">
                      Google Play
                    </div>
                  </div>
                </a>
              </div>

              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0">
                <a
                  className={`justify-between flex bg-gray-900 items-center text-lg p-5 rounded-xl transition duration-300 ease-in-out mb-3 ${
                    tab !== 1
                      ? "hover:shadow-lg hover:shadow-[#7233ff]/50"
                      : "shadow-lg shadow-[#7233ff]/50"
                  }`}
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(1);
                  }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-gray-300">
                      QR Paws: Perdido e Encontrado Simplificado
                    </div>
                    {/* <div className="text-[#94a3b8] text-base">
                      Perdeu seu pet? Encontre-o mais rápido com nossa
                      funcionalidade QR Paws! Anexe um código QR à coleira do
                      seu pet e, se alguém o encontrar, pode escanear o código
                      em nosso site. Conecte-se instantaneamente através de um
                      chat em tempo real para organizar um reencontro seguro e
                      rápido.
                    </div> */}
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-[#7233ff] rounded-full shadow flex-shrink-0 ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-qr-code-scan"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z" />
                      <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z" />
                      <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z" />
                      <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z" />
                      <path d="M12 9h2V8h-2z" />
                    </svg>
                  </div>
                </a>
                <a
                  className={`justify-between flex bg-gray-900 items-center text-lg p-5 rounded-xl transition duration-300 ease-in-out mb-3 ${
                    tab !== 2
                      ? "hover:shadow-lg hover:shadow-[#7233ff]/50"
                      : "shadow-lg shadow-[#7233ff]/50"
                  }`}
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(2);
                  }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-gray-300">
                      Social Petworking: Compartilhe e Conecte-se!
                    </div>
                    {/* <div className="text-[#94a3b8] text-base">
                      Explore uma comunidade vibrante onde proprietários de
                      animais de estimação, veterinários e cuidadores se reúnem.
                      Publique fotos, dicas e promoções especiais. A história do
                      seu pet começa aqui!
                    </div> */}
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-[#7233ff] rounded-full shadow flex-shrink-0 ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-people-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                    </svg>
                  </div>
                </a>

                <a
                  className={`justify-between flex bg-gray-900 items-center text-lg p-5 rounded-xl transition duration-300 ease-in-out mb-3 ${
                    tab !== 3
                      ? "hover:shadow-lg hover:shadow-[#7233ff]/50"
                      : "shadow-lg shadow-[#7233ff]/50"
                  }`}
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(3);
                  }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-gray-300">
                      PetChat Connect: Seu Centro Comunitário para Pets!
                    </div>
                    {/* <div className="text-[#94a3b8] text-base">
                      Junte-se à comunidade definitiva de pets! O PetChat
                      Connect reúne proprietários de animais de estimação,
                      comunidades de raças, veterinários e cuidadores em um
                      animado espaço de chat.
                    </div> */}
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-[#7233ff] rounded-full shadow flex-shrink-0 ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-chat-left-text-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                    </svg>
                  </div>
                </a>
                <a
                  className={`justify-between flex bg-gray-900 items-center text-lg p-5 rounded-xl transition duration-300 ease-in-out mb-3 ${
                    tab !== 4
                      ? "hover:shadow-lg hover:shadow-[#7233ff]/50"
                      : "shadow-lg shadow-[#7233ff]/50"
                  }`}
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(4);
                  }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-gray-300">
                      E-commerce: Acessórios Premium para Pets!
                    </div>
                    {/* <div className="text-[#94a3b8] text-base">
                      Junte-se à comunidade definitiva de pets! O PetChat
                      Connect reúne proprietários de animais de estimação,
                      comunidades de raças, veterinários e cuidadores em um
                      animado espaço de chat.
                    </div> */}
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-[#7233ff] rounded-full shadow flex-shrink-0 ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-bag-heart-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132" />
                    </svg>
                  </div>
                </a>
              </div>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1">
              <div className="transition-all">
                <div
                  className="relative flex flex-col text-center lg:text-right"
                  data-aos="zoom-y-out"
                  ref={tabs}
                >
                  {/* Item 1 */}
                  <Transition
                    show={tab === 1 || tab === 4}
                    appear={true}
                    className="w-full min-h-[800px]"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <img
                        className="md:max-w-none mx-auto rounded"
                        src={FeaturesElement1}
                        width={400}
                        height="462"
                        alt="Features bg"
                      />
                      {/* <img
                        className="md:max-w-none absolute w-full left-0 transform animate-float"
                        src={FeaturesElement1}
                        width={500}
                        height="44"
                        alt="Element"
                      /> */}
                    </div>
                  </Transition>

                  {/* Item 2 */}
                  <Transition
                    show={tab === 2}
                    appear={true}
                    className="w-full min-h-[800px]"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <img
                        className="md:max-w-none mx-auto rounded"
                        src={FeaturesElement}
                        width={400}
                        height="462"
                        alt="Features bg"
                      />
                      {/* <img
                        className="md:max-w-none absolute w-full left-0 transform animate-float"
                        src={FeaturesElement}
                        width={500}
                        height="44"
                        alt="Element"
                      /> */}
                    </div>
                  </Transition>
                  {/* Item 3 */}
                  <Transition
                    show={tab === 3}
                    appear={true}
                    className="w-full min-h-[800px]"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <img
                        className="md:max-w-none mx-auto rounded"
                        src={FeaturesElement2}
                        width={400}
                        height="462"
                        alt="Features bg"
                      />
                      {/* <img
                        className="md:max-w-none absolute w-full left-0 transform animate-float"
                        src={FeaturesElement2}
                        width={500}
                        height="44"
                        alt="Element"
                      /> */}
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import ProductCard from "components/product-card";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { products } from "utils/products";

export default function Store() {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<number>(0);

  const handlePrev = useCallback((value: number) => {
    if (value === 0) {
      setSwiper(products?.length - 1);
    } else {
      setSwiper((prev) => prev - 1);
    }
  }, []);

  const handleNext = useCallback((value: number) => {
    if (value >= products?.length - 1) {
      setSwiper(0);
    } else {
      setSwiper((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext(swiper);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [handleNext, swiper]);

  const Carousel = useCallback(() => {
    if (!products[swiper]?.image) return;

    return (
      <div className="relative w-full mt-24 mb-12 ">
        <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
          <div className="duration-700 ease-in-out">
            <img
              onClick={() =>
                navigate("/product:id", {
                  state: { id: products[swiper]?.id },
                })
              }
              src={products[swiper]?.image}
              object-fit="cover"
              className="cursor-pointer container duration-700 ease-in-out absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt=""
              width={400}
              height={400}
            />
            <div
              onClick={() =>
                navigate("/product:id", {
                  state: { id: products[swiper]?.id },
                })
              }
              className="absolute h-8 w-20 bottom-4 left-4 bg-purple-500 rounded-md  items-start justify-center flex flex-col p-2"
            >
              <p className="text-gray-300 font-bold">
                {products[swiper]?.price}
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span
            onClick={() => handlePrev(swiper)}
            className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
          >
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span
            onClick={() => handleNext(swiper)}
            className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
          >
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    );
  }, [swiper, navigate, handlePrev, handleNext]);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {Carousel()}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {products &&
            products?.map((product, i) => {
              if (!product) return null;
              return <ProductCard product={product} key={i} />;
            })}
        </div>
      </div>
    </section>
  );
}

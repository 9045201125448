import Features from "components/features";
import FeaturesBlocks from "components/features-blocks";
import React from "react";

export const metadata = {
  title: "LinkPetsApp",
  description: "Conectando pets a anjos protetores.",
};

const Home: React.FC = () => {
  return (
    <>
      <Features />
      <FeaturesBlocks />
      {/* <Testimonials /> */}
    </>
  );
};

export default Home;

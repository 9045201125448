import DonationImage from "../../assets/images/save-pet-02.jpeg";

const Donations = () => {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-start py-12">
            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              LinkPetsApp: Construindo um Futuro Melhor para Animais de Rua
            </p>
            <p className="text-base text-[#94a3b8]">
              Bem-vindo ao LinkPetsApp, onde a construção de um projeto mais
              humano e dedicado aos animais de rua é a nossa missão. Nosso
              objetivo é criar um ambiente seguro e acolhedor para os pets que
              precisam de um lar e atendimento veterinário. Sua doação faz a
              diferença, contribuindo diretamente para o bem-estar desses
              animais.
            </p>
            <br />
            <div className="glider-contain">
              <img src={DonationImage} alt={`Slide 2`} className="rounded-md" />
            </div>

            <br />

            <p className="h4 mb-4 text-gray-300">Formas de Doação</p>
            <p className="text-base text-[#94a3b8]">
              Apoie o projeto LinkPetsApp através de doações em criptomoedas
              como Bitcoin, Ethereum e Solana. Cada contribuição, por menor que
              seja, nos aproxima mais de alcançar nossos objetivos. Veja abaixo
              os endereços para doações em criptomoedas:
            </p>

            <br />

            <dl className="text-base text-gray-300 font-bold">
              <li>
                Bitcoin (BTC):{"  "}
                <span className="text-[#7233ff]">
                  1LQhrs9G5CgddM9QKPQVyykdCYzATF5npB
                </span>
              </li>
              <li>
                Ethereum (ETH):{"  "}
                <span className="text-[#7233ff]">
                  0x2541112d502b1a0e06f976e8c73cd35923b2552e
                </span>
              </li>
              <li>
                Solana (SOL):{"  "}
                <span className="text-[#7233ff]">
                  AKTFJ3DXbuRpuBBUbg86izj982s4TXk5FGUJ9ddTQxVS
                </span>
              </li>
            </dl>

            <br />

            <p className="text-base text-[#94a3b8]">
              Para outras formas de doação ou para esclarecer dúvidas, entre em
              contato conosco pelo e-mail{" "}
              <a
                className="text-[#7233ff] font-bold hover:underline"
                href="mailto:contact@linkpetsapp.xyz"
              >
                contact@linkpetsapp.xyz
              </a>
              . Sua generosidade ajuda a transformar vidas e a construir um
              futuro mais brilhante para esses pets especiais. Junte-se a nós
              nessa jornada de compaixão e amor pelos animais. Obrigado por
              fazer parte dessa causa.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Donations;

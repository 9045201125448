import { useUser } from "context/user";
import Logo from "./logo";

export default function Footer() {
  const { user } = useUser();
  return (
    <footer className=" rounded-lg shadow m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a href="https://" className="flex items-center mb-4 sm:mb-0">
            <Logo />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
              {" "}
              LinkPetsApp
            </span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium  sm:mb-0 text-[#94a3b8]">
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/apetsapp/?viewAsMember=true"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://discord.gg/GBC4Dynq"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                rel="noreferrer"
              >
                Discord
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/apetsapp/"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/linkpetsapp"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://t.me/+JyYOufTZ1Ww5NzNh"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                rel="noreferrer"
              >
                Telegram
              </a>
            </li>

            <li>
              <a
                href="/store"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                Loja
              </a>
            </li>

            <li>
              <a
                href="/blog"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                Blog
              </a>
            </li>

            <li>
              <a
                href="/terms-&-conditions"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                Terms of use
              </a>
            </li>
            <li>
              <a
                href="/donations"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                Doações
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                Contato
              </a>
            </li>
            <li>
              <a
                href="/signin"
                className="font-medium text-[#94a3b8] hover:text-[#fff] px-5 py-3 flex items-center transition duration-150 ease-in-out"
              >
                {user ? "Perfil" : "Entrar"}
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
        <span className="block text-sm sm:text-center text-[#94a3b8]">
          © 2024{" "}
          <a href="https://scriptembal.pt/" className="hover:underline">
            Scriptembal™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

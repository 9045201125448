import { useUser } from "context/user";
import { useState } from "react";

export default function Dashboard() {
  const { user, balanceWallet, resetStorage } = useUser();
  const [optionSelected, setOptionSelected] = useState("Meus dados");
  const [options] = useState(["Meus dados", "Loja", "Produtos"]);

  return (
    <div className="md:pt-16 p-0">
      <div className="p-8 bg-[#222] shadow mt-24 opacity-80 rounded-xl">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
            {options.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => setOptionSelected(item)}
                  className={`rounded-full ${
                    item === optionSelected ? "bg-purple-600 " : ""
                  } flex items-center justify-center px-4 mx-2 cursor-pointer`}
                >
                  <p className="text-gray-200">{item}</p>
                </div>
              );
            })}
          </div>

          <div className="relative">
            <div className="w-48 h-48 bg-[#222] mx-auto rounded-full absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
              {user?.image ? (
                <img
                  alt="avatar"
                  className="h-full w-full p-2 object-cover object-center rounded-full"
                  src={user?.image}
                  object-fit="cover"
                />
              ) : (
                <img
                  alt="avatar"
                  className="h-32 w-32 object-cover object-center rounded-full"
                  src={`https://api.dicebear.com/6.x/bottts/png?seed=${user?.id}`}
                  object-fit="cover"
                />
              )}
            </div>
          </div>

          {/* <div className="space-x-8 flex justify-between mt-32 md:mt-4 md:justify-center">
            <button className="text-white px-4 h-8  uppercase rounded-xl bg-purple-500 hover:bg-purple-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                    Mensagem
                  </button>

            <button
              onClick={resetStorage}
              className="text-white px-4 h-8 uppercase rounded-xl bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              Sair
            </button>
          </div> */}
        </div>

        <div className="mt-20 text-start border-b-[0.3px] border-purple-500 pb-12">
          <h1 className="text-4xl font-medium text-gray-100">
            {user?.userName || "User"}
          </h1>

          {user?.address && (
            <p className="font-light text-[#94a3b8] mt-3">
              {user?.address?.city}, {user?.address?.countryCode}
            </p>
          )}

          <p className="mt-8 text-[#94a3b8]">{user?.email}</p>

          <p className="mt-8 text-[#94a3b8] truncate">{user?.publicAddress}</p>
        </div>

        <div className="mt-12 flex flex-col justify-center">
          <p className="text-[#94a3b8] text-center font-light lg:px-16">
            {user?.bio}
          </p>
          <button className="text-purple-500 py-2 px-4  font-medium mt-4">
            Show more
          </button>
          <button
            onClick={resetStorage}
            className="text-white px-4 h-8 uppercase rounded-xl bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  );
}

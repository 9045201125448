export default function TermsConditions() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-start py-12">
            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              Terms & Conditions
            </p>
            <p className="text-base text-[#94a3b8]">
              Welcome to LinkPetsApp, a unique social network designed to
              connect pet lovers, provide online chat support with
              veterinarians, and foster communities based on pet breeds. Please
              carefully read and understand our Terms & Conditions before using
              our services.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              1. Acceptance of Terms
            </p>
            <p className="text-base text-[#94a3b8]">
              By accessing or using the LinkPetsApp mobile application (the
              "App") and its services, you agree to comply with and be bound by
              these Terms & Conditions. If you do not agree with any part of
              these terms, you must not use our services.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              2. User Wallets on Solana Network
            </p>
            <p className="text-base text-[#94a3b8]">
              During your initial authentication, a Solana-based wallet will be
              created for you. This digital wallet is integral to our services,
              facilitating transactions, private consultations, and in-app
              purchases. It is your responsibility to safeguard your wallet
              information.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              3. User Responsibilities
            </p>
            <dl className="text-base text-[#94a3b8]">
              <li>
                You are responsible for maintaining the confidentiality of your
                login credentials, including your Solana wallet.
              </li>
              <li>
                You must not engage in any activity that may disrupt the
                functioning of the App or compromise its security.
              </li>
              <li>
                You agree not to share, transmit, or make available any content
                that is unlawful, abusive, offensive, or infringes on the rights
                of others.
              </li>
              <li>
                Do not impersonate or misrepresent your affiliation with any
                person or entity.
              </li>
            </dl>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              4. User Content
            </p>
            <dl className="text-base text-[#94a3b8]">
              <li>
                You retain ownership of the content you post, share, or submit
                on the App.
              </li>
              <li>
                By sharing content, you grant us a worldwide, non-exclusive,
                royalty-free license to use, copy, modify, and distribute your
                content.
              </li>
            </dl>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              5. In-App Purchases
            </p>
            <p className="text-base text-[#94a3b8]">
              Our App may offer in-app purchases for various services or virtual
              goods. All purchases are non-refundable and must comply with the
              terms and conditions of app stores from which the App was
              downloaded.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              6. Privacy
            </p>
            <dl className="text-base text-[#94a3b8]">
              <li>
                Your privacy is important to us. Our Privacy Policy outlines our
                practices concerning the collection and use of your personal
                information.
              </li>
              <li>
                You consent to our collection and use of your data, as described
                in the Privacy Policy.
              </li>
            </dl>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              7. Termination of Services
            </p>
            <p className="text-base text-[#94a3b8]">
              We reserve the right to suspend or terminate your access to the
              App without notice if you breach these Terms & Conditions.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              8. Changes to Terms & Conditions
            </p>
            <p className="text-base text-[#94a3b8]">
              We may update these Terms & Conditions from time to time. It is
              your responsibility to check for updates regularly. Your continued
              use of the App after any changes indicates your acceptance of the
              modified terms.
            </p>

            <br />

            <p className="font-bold tracking-tight text-gray-200 text-2xl">
              9. Contact Us
            </p>
            <p className="text-base text-[#94a3b8]">
              If you have any questions or concerns regarding these Terms &
              Conditions or our services, please contact us at
              contact@linkpetsapp.xyz.
            </p>

            <br />
            <p className="text-base text-[#94a3b8]">
              Thank you for using LinkPetsApp. We hope you and your pets have a
              wonderful experience with our platform.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

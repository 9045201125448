import "@solana/wallet-adapter-react-ui/styles.css";
import Banner from "components/banner";
import Footer from "components/ui/footer";
import Header from "components/ui/header";
import AppProvider from "context";
import React from "react";
import Routes from "routes";
import "styles/globals.css";

const App: React.FC = () => {
  return (
    <div
      className={`bg-gradient-to-b from-[#3A1980] via-gray-900 to-[#3A1980] text-gray-900 tracking-tight`}
    >
      <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
        <div
          className="absolute inset-x-0 top-[-10rem] transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          aria-hidden="true"
        >
          <div
            className="left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            // style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          />
        </div>

        <AppProvider>
          <>
            <Header />
            <Routes />
            <Banner />
            <Footer />
          </>
        </AppProvider>
      </div>
    </div>
  );
};

export default App;
